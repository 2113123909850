import React from 'react';

import Card from '@components/card/card.component';
import SectionTitle from '@components/section-title/section-title.component';

import PhoneIcon from '@icons/phone.svg';
import MailIcon from '@icons/mail.svg';

import ContactStyled from './contact.styles';

const ContactIcon = ({ icon, children }) => (
  <div className='contact-info'>
    <div className='icon'>
      {icon}
    </div>
    <p className='body-text s primary scalable'>{children}</p>
  </div>
);

const ContactComponent = () => (
  <Card>
    <ContactStyled>
      <div className='v-align'>
        <SectionTitle>
          Entre em
          <br />
          <span className='highlight'>contato conosco!</span>
        </SectionTitle>
      </div>
      <div className='v-line hide-laptop' />
      <div className='info'>
        <ContactIcon icon={<PhoneIcon />}>
          <a href='tel:+555432924473'>
            (54) 3292-4473
          </a>
        </ContactIcon>
        <div className='h-line show-laptop' />
        <ContactIcon icon={<MailIcon />}>
          <a href='mailto:vendas@cdfequipamentos.com.br'>
            vendas@cdfequipamentos.com.br
          </a>
        </ContactIcon>
      </div>
    </ContactStyled>
  </Card>
);

export default ContactComponent;
