import styled from 'styled-components';
import { deviceMediaQ } from '../../theme';

const AboutUsStyles = styled.div`
  margin-top: 150px;
  
  @media ${deviceMediaQ.tablet} {
    margin-top: 100px;
  }
  
  .about-us {
    display: flex;
    flex-direction: row;
    margin: 16px 0 16px 0;

    .panel {
      &.left {
        width: 65%;
        font-weight: bold;
        font-size: 40px;
        line-height: 116%;

        .images {
          display: flex;
          align-items: center;
          gap: 23px;
          height: 60%;

          .left-image-container {
            height: 70%;
          }

          .right-image-container {
            height: 100%;
          }

        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;
          height: 40%;
          width: 80%;
        }
      }

      &.right {
        display: flex;
        align-items: flex-end;
        width: 35%;
        height: 95vh;
        margin-left: 23px;

        @media ${deviceMediaQ.laptopL} {
          height: 98vh;
        }

        .container-full-height {
          height: 85%;
        }
      }

      img {
        border-radius: 8.83px;
      }
    }
  }
  
  .mobile-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 9px;
    
    img {
      max-height: 115px;
      min-height: 105px;
    }

    .rounded-top-left {
      border-radius: 10.1585px 0 0 0;
    }

    .rounded-top-right {
      border-radius: 0 10.1585px 0 0;
    }

    .rounded-bottom-right {
      border-radius: 0 0 10.1585px 0;
    }

    .rounded-bottom-left {
      border-radius: 0 0 0 10.1585px;
    }
  }
  
  .mobile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  button {
    width: fit-content;
    a {
      padding: 15px 54px 15px 54px;
      font-style: normal;
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0.14em;
      color: #FCFDFE;

      @media ${deviceMediaQ.mobile} {
        padding: 8px 25px 8px 25px;
        font-size: 10px;
      }
    }
  }

  @media ${deviceMediaQ.tablet} {
    button {
      margin-top: 15px;
    }
  }
`;

export default AboutUsStyles;
