import styled from 'styled-components';
import Theme, { deviceMediaQ } from '@src/theme';

const DotsBackgroundStyled = styled.div`
  position: absolute;
  width: 50%;
  
  &.top-right {
    top: -17%;
    right: calc(-${Theme.pageContainerPaddingSz} / 2);

    @media ${deviceMediaQ.laptop} {
      top: -10%;
    }

    @media ${deviceMediaQ.tablet} {
      top: -5vw;
      right: -${Theme.pageContainerPaddingMobileSz};
    }
  }
  
  &.bottom-left {
    bottom: -17%;
    left: calc(-${Theme.pageContainerPaddingSz} / 2);
    
    @media ${deviceMediaQ.tablet} {
      bottom: -1vw;
      left: -${Theme.pageContainerPaddingSz};
    }
    
    @media ${deviceMediaQ.laptop} {
      bottom: -10%;
    }
    
    @media ${deviceMediaQ.tablet} {
      bottom: -5vw;
      left: -${Theme.pageContainerPaddingMobileSz};
    }
  }
`;

export default DotsBackgroundStyled;
