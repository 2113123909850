import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import PageContainer from '@components/page-container/page-container.component';
import Hero from '@components/hero/hero.component';
import Layout from '@components/layout/layout.component';
import Contact from '@components/contact/contact.component';
import AboutUs from '@components/about-us/about-us.component';
import ProductCarousel from '@components/product-carousel/product-carousel.component';
import DotsBackground from '@components/dots-background/dots-background.component';

import WavyShape from '@images/wavy-shape.svg';

import WhereWeAreComponent from '@components/where-we-are/where-we-are.component';
import useDeviceDetect from '@src/utils/useMobileDetect';
import { getProductsUrlByType } from '@src/utils/product.utils';

const IndexPage = ({ location }) => {
  const { allProductsJson: { nodes: products } } = useStaticQuery(graphql`
      query {
          allProductsJson {
              nodes {
                  slug
                  type
                  title
                  images
                  features
                  accessories
                  datasheet_url
                  doc_url
                  dimensions_list
                  dimensions {
                      l
                      a
                      p
                  }
              }
          }
      }
  `);

  const resonanceProducts = React.useMemo(
    () => products.filter((p) => p.type === 'resonance'),
    [products],
  );

  const rescueProducts = React.useMemo(
    () => products.filter((p) => p.type === 'rescue'),
    [products],
  );

  const { isMobile } = useDeviceDetect();

  return (
    <Layout title='CDF Equipamentos' location={location} headerOptions={{ disableShadow: !isMobile }}>
      <PageContainer className='add-margin-to-children'>
        <Hero />
      </PageContainer>
      <div className='relative'>
        <WavyShape className='wavy-background flip-v'/>
        <PageContainer className='add-margin-to-children' style={{ paddingTop: '50px' }}>
          <ProductCarousel
            products={rescueProducts}
            title='resgate'
            dotsBg={<DotsBackground topRight />}
            link={getProductsUrlByType('rescue')}
          />
          <ProductCarousel
            products={resonanceProducts}
            title='ressonância magnética'
            dotsBg={<DotsBackground />}
            link={getProductsUrlByType('resonance')}
          />
        </PageContainer>
      </div>
      <PageContainer className='add-margin-to-children'>
        <AboutUs />
      </PageContainer>
      <div className='relative'>
        <WavyShape/>
        <PageContainer className='add-margin-to-children'>
          <Contact />
          <WhereWeAreComponent />
        </PageContainer>
      </div>
    </Layout>
  );
};

export default IndexPage;
