import Theme from '@src/theme';
import styled from 'styled-components';

const WhereWeAreStyled = styled.div`
  height: 45vh;
  display: flex;
  gap: 20px;
  position: relative;
  
  .text {
    color: ${Theme.darkColor};
  }
  
  .title {
    margin-bottom: 15px;
  }
  
  .map-container {
    width: 100%;
  }
  
  .map-title {
    position: absolute;
    background: white;
    top: 10px;
    left: 10px;
    padding: 12px 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 2px;
    z-index: 50;
  }
  
  .map {
    height: 100%;
    width: 100%;
  }
  
  address {
    
  }
`;

export default WhereWeAreStyled;
