import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import SectionTitle from '@components/section-title/section-title.component';
import WhereWeAreStyled from '@components/where-we-are/where-we-are.styles';

const mapCenter = {
  lat: -29.060,
  lng: -51.192,
};

const markerPosition = {
  lat: -29.06021462569213,
  lng: -51.19254174320376,
};

const WhereWeAreComponent = () => {
  // const [mapLoaded, setMapLoaded] = React.useState(false);

  return (
    <>
      <SectionTitle>Onde estamos</SectionTitle>
      <WhereWeAreStyled>
        {/*{mapLoaded ? (<a*/}
        {/*  href='https://www.google.com/search?q=cdf+equipamentos&oq=cdf+equip&aqs=chrome.0.69i59j69i57j69i59l2j0i22i30j69i60l3.1893j0j7&sourceid=chrome&ie=UTF-8#'*/}
        {/*  rel='noreferrer'*/}
        {/*  target='_blank'*/}
        {/*  className='map-title text'*/}
        {/*>*/}
        {/*  CDF Equipamentos*/}
        {/*</a>*/}
        {/*) : null}*/}

        <div className='map-container'>
          <LoadScript googleMapsApiKey='AIzaSyDyO0K3GVBjX-N753b2UYXZl-_uTQpU_ws' language='pt-BR'>
            <GoogleMap
              center={mapCenter}
              zoom={16}
              mapContainerClassName='map'
              // onLoad={() => {
              //   setMapLoaded(true);
              // }}
            >
              <Marker position={markerPosition} title='CDF Equipamentos' />
            </GoogleMap>
          </LoadScript>
        </div>
        <address className='hide-mobile text'>
          Rua Leandro João Marcon, 507 - Vilagio (AABB, Flores da Cunha - RS, 95270-000
        </address>
      </WhereWeAreStyled>
    </>
  );
};

export default WhereWeAreComponent;
