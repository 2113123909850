import styled from 'styled-components';
import Theme, { deviceMediaQ } from '../../theme';

const HeroStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 9vh auto 17vh auto !important;

  @media ${deviceMediaQ.tablet} {
    margin: 0 auto 5vh 0 !important;
  }

  @media ${deviceMediaQ.tablet} {
    flex-direction: column-reverse;
  }

  h1 {
    font-size: 44px;
    font-weight: 500;
    line-height: 55px;

    span {
      color: ${Theme.secondaryColor};
      font-weight: 700;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex: 1;
    margin-right: 50px;
  }

  button {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    background: ${Theme.primaryColor};
    color: ${Theme.lightColor};
    font-weight: 800;
    letter-spacing: 0.14em;
    padding: 14px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0;
    font-size: 16px;
    width: fit-content;
  }

  h4 {
    margin-top: 16px;
    color: #616161;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 126%;
  }

    //height: calc(70vh - ${Theme.headerSize});

  .media {
    width: 50%;
    z-index: 1;

    .img {
      border-radius: 24px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }
    
    img {
      border-radius: 24px;

      @media ${deviceMediaQ.laptop} {
        border-radius: 0 0 24px 24px;
      }
    }

    @media ${deviceMediaQ.laptop} {
      margin: 40px auto;
    }

    @media ${deviceMediaQ.tablet} {
      .gatsby-image-wrapper {
        overflow: visible;
      }

      // 100 % + os 20px de padding de cada lado do container
      width: calc(100% + 40px);;
      margin: -5px -${Theme.pageContainerPaddingMobileSz} 25px -${Theme.pageContainerPaddingMobileSz};
    }
  }
`;

export default HeroStyled;
