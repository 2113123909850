import React from 'react';

import DotsBackgroundStyled from '@components/dots-background/dots-background.styles';

import DotsSvg from '@images/dots-primary.svg';
import DotsSecondarySvg from '@images/dots-seconday.svg';

const DotsBackgroundComponent = ({ topRight = false, ...props }) => {
  const dotsClass = topRight ? 'top-right' : 'bottom-left';

  if (!props.className) {
    props.className = dotsClass;
  } else {
    props.className += ` ${dotsClass}`;
  }

  return (<DotsBackgroundStyled {...props}>
      {topRight ? <DotsSecondarySvg /> : <DotsSvg />}
    </DotsBackgroundStyled>
  );
};

export default DotsBackgroundComponent;
