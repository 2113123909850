import Theme, { deviceMediaQ } from '@src/theme';
import styled from 'styled-components';

const ContactStyles = styled.address`
  height: 276px;
  display: flex;

  @media ${deviceMediaQ.laptop} {
    height: 225px;
    flex-direction: column;
  }

  .text {
    display: flex;
    align-items: center;

    @media ${deviceMediaQ.tablet} {
      margin-bottom: 26px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    width: 45%;

    @media ${deviceMediaQ.laptop} {
      width: 100%;
      margin-top: auto;
    }
  }

  .v-line {
    width: 0.5px;
    height: 100%;
    background-color: rgba(196, 196, 196, 0.3);
    margin-left: auto;
    margin-right: auto;
  }
  
  .v-align {
    height: 100%;
  }

  .h-line {
    height: 0.5px;
    width: 100%;
    background-color: rgba(196, 196, 196, 0.3);
    margin: auto 0;
  }

  .contact-info {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 15px;
      display: flex;
      align-items: center;
    }
    
    a {
      text-decoration: none;
      color: ${Theme.primaryColor};
    }
  }
`;

export default ContactStyles;
