import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import SectionTitle from '@components/section-title/section-title.component';
import Button from '@components/button/button.component';

import WhatsappIcon from '@icons/whatsapp.svg';
import HeroStyled from './hero.styled';
import Theme, { deviceMediaQ } from '../../theme';

import useDeviceDetect from '@src/utils/useMobileDetect';

const RightBlueSection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30vw;

  @media ${deviceMediaQ.laptop} {
    width: 25vw;
  }

  @media ${deviceMediaQ.laptopL} {
    width: 20vw;
  }

  height: 75vh;

  background: ${Theme.primaryColor};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px 0 0 30px;
`;

const Hero = () => {
  const { isMobile } = useDeviceDetect();

  return (
    <HeroStyled>
      <RightBlueSection className='hide-mobile' />
      <section>
        <SectionTitle>Equipamentos para <span className='highlight'>transporte hospitalar</span></SectionTitle>
        <h4>Projetamos e produzimos equipamentos para transporte hospitalar, resgate e ressonância magnética. Tendo
          como prioridade: Segurança, conforto, qualidade e praticidade!</h4>
        <Button icon={<WhatsappIcon />}>
          <a href='https://api.whatsapp.com/send?phone=54992765020' target='_blank' rel='noreferrer'
             className='button'>
            ENTRE EM CONTATO
          </a>
        </Button>
      </section>
      <div className='media'>
        <ReactPlayer
          url="https://youtu.be/Mnyl5k-NqJg"
          muted={!isMobile}
          playing={!isMobile}
          width="100%"
        />
      </div>
    </HeroStyled>
  );
}

export default Hero;
