import Theme, { deviceMediaQ } from '@src/theme';
import styled from 'styled-components';

const ProductCarouselStyled = styled.section`
  position: relative;
  margin-bottom: 180px;

  .section-title {
    margin-bottom: 50px;
  }

  .card {
    position: relative;
    
    @media ${deviceMediaQ.tablet} {
      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }

  .chevron {
    background: inherit;
    border: none;
    position: absolute;
    top: 40%;
    padding: inherit;
    cursor: pointer;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }

    svg {
      opacity: 0.3;
      transition: 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    
    @media ${deviceMediaQ.tablet} {
      height: 30vh;
      width: 45vw;
    }

    .img {
      height: 330px;
      margin-bottom: 20px;
    }

    .title {
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 500;
      margin: 20px auto;
      text-align: center;
      
      a {
        color: ${Theme.primaryColor};
      }
      
      @media ${deviceMediaQ.tablet} {
        font-size: 6vw;
      }
    }
  }

  .products-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 20px 0;
    margin: 0 -${Theme.pageContainerPaddingMobileSz};
  }
  
  .link {
    color: ${Theme.secondaryColor};
    position: absolute;
    right: 0;
  }
  
  .carousel-wrapper {
    position: relative;
  }
`;

export default ProductCarouselStyled;
