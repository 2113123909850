import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import AboutUsStyled from './about-us.styles';

import Button from '@components/button/button.component';
import SectionTitle from '@components/section-title/section-title.component';
import useDeviceDetect from '@src/utils/useMobileDetect';

const AboutUsComponent = () => {
  const { isMobile } = useDeviceDetect();

  return (
    <AboutUsStyled>
      {
        isMobile ?
          <div className='mobile-content'>
            <div className='mobile-images'>
              <StaticImage src='../../images/bg00.jpeg' alt='' className='rounded-top-left'/>
              <StaticImage src='../../images/bg01.jpeg' alt=''/>
              <StaticImage src='../../images/bg05.jpeg' alt='' className='rounded-top-right'/>
              <StaticImage src='../../images/bg04.jpeg' alt='' className='rounded-bottom-left'/>
              <StaticImage src='../../images/bg02.jpeg' alt=''/>
              <StaticImage src='../../images/bg03.jpeg' alt='' className='rounded-bottom-right'/>
            </div>

            <Button primary={true}>
              <Link to='/sobre-nos'>QUEM SOMOS NÓS</Link>
            </Button>
          </div>
          :
          <div className='about-us'>
            <div className='panel left'>
              <div className='images'>
                <StaticImage src='../../images/bg02.jpeg' alt='' className='left-image-container'/>
                <StaticImage src='../../images/bg03.jpeg' alt='' className='right-image-container'/>
              </div>
              <div className='content'>
                <SectionTitle>Especialistas em produtos de <span className='highlight'>resgate</span> e <span className='highlight'>ressonância magnética</span></SectionTitle>
                <Button primary={true}>
                  <Link to='/sobre-nos'>SOBRE NÓS</Link>
                </Button>
              </div>
            </div>

            <div className='panel right'>
              <StaticImage src='../../images/bg04.jpeg' alt='' className='container-full-height'/>
            </div>
          </div>
      }
    </AboutUsStyled>
  )
};

export default AboutUsComponent;
