import React from 'react';
import { Link } from 'gatsby';

import Card from '@components/card/card.component';
import SectionTitle from '@components/section-title/section-title.component';
import ProductImage from '@components/product-image/product-image.component';

import ProductCarouselStyled from '@components/product-carousel/product-carousel.styles';

import ChevronLeft from '@icons/chevron-left.svg';
import ChevronRight from '@icons/chevron-right.svg';

import useDeviceDetect from '@src/utils/useMobileDetect';
import { getProductUrl } from '@src/utils/product.utils';


export const DesktopCarousel = ({ products }) => {
  const [product, setProduct] = React.useState(null);
  const [productIdx, setProductIdx] = React.useState(-1);

  const mProducts = React.useMemo(() => products.filter((p) => !!p.images.length), [products]);

  React.useEffect(() => {
    let idx;

    if (mProducts.length <= 1) {
      idx = 0;
    } else {
      idx = Math.floor(Math.random() * mProducts.length);
    }


    setProductIdx(idx);
    setProduct(mProducts[idx]);
  }, [mProducts]);

  const updateProduct = (amount) => {
    let idx = productIdx + amount;

    if (idx === mProducts.length) idx = 0;
    if (idx === -1) idx = mProducts.length - 1;

    setProductIdx(idx);
    setProduct(mProducts[idx]);
  };

  if (!product) {
    return null;
  }

  return (
    <Card className='card'>
      {
        mProducts.length > 1 ? (
          <>
            <button className='chevron left' onClick={() => updateProduct(-1)}>
              <ChevronLeft />
            </button>
            <button className='chevron right' onClick={() => updateProduct(1)}>
              <ChevronRight />
            </button>
          </>
        ) : null
      }

      <div className='product-container'>
        <ProductImage product={product} className='img' objectFit='contain' />
        <h3 className='title'>{product.title}</h3>
      </div>

    </Card>
  );
};

export const MobileCarousel = ({ products, link }) => {
  return (
    <>
      <div className='products-container'>
        {products.map((product) => {
          return (
            <Card className='card' key={product.slug}>
              <div className='product-container'>
                <ProductImage product={product} className='img' objectFit='contain' />
                <h3 className='title'><Link to={getProductUrl(product)}>{product.title}</Link></h3>
              </div>
            </Card>
          );
        })}
      </div>
      <Link to={link} className='link'>ver mais</Link>
    </>
  );
};

const ProductCarouselComponent = ({ products, title, dotsBg, link }) => {
  const { isMobile } = useDeviceDetect();

  return (
    <ProductCarouselStyled>
      <SectionTitle className='section-title'>
        <Link to={link}>
          Nossos produtos de
          <br />
          <span className='highlight'>{title}</span>
        </Link>
      </SectionTitle>
      <div className='carousel-wrapper'>
        {dotsBg}
        {isMobile ? <MobileCarousel products={products} link={link} /> : <DesktopCarousel products={products} />}
      </div>
    </ProductCarouselStyled>
  );
};

export default ProductCarouselComponent;
